import React from "react";
import Fade from "react-reveal/Fade";

import Layout from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SocialLinks from "../components/SocialLinks";
const IndexPage = () => (
  <Layout title="cookies" description="Informações sobre o uso de cookies">
    <Header
      title="Cookies"
      frontPage={false}
      smallSubtitle="Informações sobre o uso de cookies"
    />
    <div className="content pt-5">
      <div className="container pt-5">
        <div className="row justify-content-md-center">
          <Fade>
            <div className="col-sm-12 text-color">
              <h3 className="p-5">O que são cookies?</h3>
              <p>
                {" "}
                "Cookies" são pequenas etiquetas de software que são armazenadas
                nos equipamentos de acesso através do navegador(browser),
                retendo apenas informação relacionada com as preferências, não
                incluindo, como tal, os dados pessoais.
              </p>
              <h3 className="p-5">Para que servem os Cookies?</h3>
              <p>
                {" "}
                Os cookies servem para ajudar a determinar a utilidade,
                interesse e o número de utilizações dos websites, permitindo uma
                navegação mais rápida e eficiente, eliminando a necessidade de
                introduzir repetidamente as mesmas informações.
              </p>
              <h3 className="p-5">Que tipo de cookies existem?</h3>
              <p> Existem dois grupos cookies que podem ser utilizados </p>
              <p>
                Cookies permanentes - são cookies que ficam armazenados ao nível
                do browser nos equipamentos de acesso(PC, mobile e tablet) e que
                são utilizados sempre que faz uma nova visita ao nosso
                website.São utilizados, geralmente, para direcionar a navegação
                aos interesses do utilizador, permitindo prestar um serviço mais
                personalizado.{" "}
              </p>
              <p>
                Cookies de sessão - são cookies temporários que permanecem no
                arquivo de cookies do browser até sair do website.A informação
                obtida por estes cookies serve para analisar padrões de tráfego
                na web, permitindo identificar problemas e fornecer uma melhor
                experiencia de navegação.{" "}
              </p>
              <h3 className="p-5">Para que fins utilizamos cookies ?</h3>
              <p>
                Cookies estritamente necessários - Permitem a navegação no
                website e utilização das aplicações, bem como aceder a áreas
                seguras do website.Sem estes cookies, os serviços requerido não
                podem ser prestados.{" "}
              </p>
              <p>
                Cookies analíticos - São utilizados anonimamente para efeitos de
                criação e análise de estatísticas, no sentido de melhorar o
                funcionamento do website.{" "}
              </p>
              <p>
                Cookies de funcionalidade - Guardam as preferências do
                utilizador relativamente à utilização do site, para que não seja
                necessário voltar a configurar o site cada vez que o visita.{" "}
              </p>
              <p>
                Cookies de terceiros - Medem o sucesso de aplicações e a
                eficácia da publicidade de terceiros.Podem também ser utilizados
                no sentido de personalizar um widget com dados do utilizador.{" "}
              </p>
              <p>
                {" "}
                Cookies de publicidade - Direcionam a publicidade em função dos
                interesses de cada utilizador, por forma a direcionar as
                campanhas publicitárias tendo em conta os gostos dos
                utilizadores, sendo que, além disso, limitam a quantidade de
                vezes que vê o anúncio, ajudando a medir a eficácia da
                publicidade e o sucesso da organização do website.{" "}
              </p>
              <h3 className="p-5">Como pode gerir os cookies?</h3>
              <p>
                {" "}
                Todos os browsers permitem ao utilizador aceitar, recusar ou
                apagar cookies, e ainda informar o utilizador sempre que um
                cookie é recebido, nomeadamente através da seleção das
                definições apropriadas no respetivo navegador.O utilizador pode
                configurar os cookies no menu "opções" ou "preferências" do seu
                browser.
              </p>
              <p></p>
              <p>
                Note - se, no entanto, que, ao desativar cookies, pode impedir
                que alguns serviços da web funcionem corretamente, afetando,
                parcial ou totalmente, a navegação no website.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
